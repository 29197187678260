var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('VSelect',{attrs:{"items":_vm.stages,"loading":_vm.isLoading,"item-value":"id","item-text":"name","outlined":"","hide-details":"","label":_vm.$('stage')},model:{value:(_vm.stage),callback:function ($$v) {_vm.stage=$$v},expression:"stage"}}),_c('v-spacer'),_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('timetable.create'),expression:"'timetable.create'"}],attrs:{"id":"btn1","color":"primary","dark":""},on:{"click":function($event){return _vm.dialogModal(true, 'create')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$('create'))+" ")],1)],1),(!_vm.isLoading && _vm.stage)?_c('v-data-table',{staticClass:"dd",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"disable-filtering":true,"footer-props":{
        showFirstLastPage: true,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
        itemsPerPageOptions:[10, 20, 50, -1],
        itemsPerPageText: _vm.$t('dataTable.itemsPerPageText'),
        pageText: _vm.$t('dataTable.pageText'),
        itemsPerPageAllText: _vm.$t('dataTable.itemsPerPageAllText'),
      },"hide-default-header":!_vm.$vuetify.breakpoint.mobile},on:{"update:options":function($event){_vm.options=$event},"update:page":_vm.fetch,"update:items-per-page":_vm.fetch},scopedSlots:_vm._u([(!_vm.$vuetify.breakpoint.mobile)?{key:"header",fn:function(ref){
      var props = ref.props;
return [_c('datatable-header',{attrs:{"props":props,"options":_vm.options,"translation-key":_vm.translationKey},on:{"update:options":function($event){_vm.options=$event},"update:filters":_vm.fetch}})]}}:null,{key:"header.actions",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-center",attrs:{"id":"actions"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"dark"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[_c('menu-item',{directives:[{name:"can",rawName:"v-can",value:('timetable.edit'),expression:"'timetable.edit'"}],attrs:{"label":_vm.$('update'),"color":"info","icon":"mdi-pencil"},on:{"click":function($event){return _vm.dialogModal(true, 'update', item.id)}}}),_c('menu-item',{directives:[{name:"can",rawName:"v-can",value:('timetable.delete'),expression:"'timetable.delete'"}],attrs:{"label":_vm.$('delete'),"color":"error","icon":"mdi-delete"},on:{"click":function($event){return _vm.dialogModal(true, 'delete', item.id)}}})],1)],1)],1)]}},{key:"item.day",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$(("days." + (item.day))))+" ")]}},{key:"item.study_type",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("study_types." + (item.study_type))))+" ")]}},{key:"item.from",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.timeFormat(item.from))+" ")]}},{key:"item.to",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.timeFormat(item.to))+" ")]}}],null,true)}):_vm._e(),_c('action-dialog',{attrs:{"dialog-modal":_vm.dialogModal,"data":_vm.dialogData,"translation-key":_vm.translationKey},on:{"update:table":_vm.fetch}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }