<template>
  <v-dialog v-model="data.dialog" persistent max-width="600px">
    <v-card v-if="loading" :color="isDelete ? 'error' : 'primary'" dark>
      <v-card-text>
        <span class="font-weight-bold text-white">{{ $t('loading') }}</span>
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-if="!loading">
      <v-card-title
        :class="{'form-danger': isDelete, 'form-info': isUpdate, 'form-primary': isCreate}"
      >
        <span v-if="isCreate" class="">{{ $('create') }}</span>
        <span v-if="isUpdate" class="">{{ $('update') }}</span>
        <span v-if="isDelete" class="">{{ $('delete') }}</span>
      </v-card-title>
      <v-card-text>
        <h2 v-if="isDelete && item" class="my-3">
          {{ $('delete_msg', [item.name]) }}
        </h2>
        <v-form
          v-if="!isDelete"
          ref="form"
          v-model="valid"
        >
          <v-container>
            <v-row>
              <v-col
                cols="6"
                sm="6"
              >
                <v-select
                  v-model="form.day"
                  :items="days"
                  :label="$('day')"
                  :rules="rules.day"
                  item-value="value"
                  item-text="title"
                ></v-select>
              </v-col>
              <v-col
                cols="6"
                sm="6"
              >
                <v-select
                  v-model="form.study_type"
                  :items="study_types"
                  :label="$('study_type')"
                  :rules="rules.study_type"
                  item-value="value"
                  item-text="title"
                ></v-select>
              </v-col>
              <v-col
                cols="6"
                sm="6"
              >
                <v-select
                  v-model="form.subject_id"
                  :items="data.subjects"
                  :label="$('subject_id')"
                  :rules="rules.subject_id"
                  item-value="id"
                  item-text="name"
                ></v-select>
              </v-col>
              <v-col
                cols="6"
                sm="6"
              >
                <v-text-field
                  v-model="form.class_room"
                  :label="$('class_room')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="6"
                sm="6"
              >
                <v-select
                  v-model="form.from"
                  :items="timeArray"
                  :label="$('from')"
                  :rules="rules.from"
                  item-value="value"
                  item-text="title"
                ></v-select>
              </v-col>
              <v-col
                cols="6"
                sm="6"
              >
                <v-select
                  v-model="form.to"
                  :items="endTimeArray"
                  :label="$('to')"
                  :rules="rules.to"
                  item-value="value"
                  item-text="title"
                  :disabled="!form.from"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="light"
          outlined
          @click="dialogModal(false)"
        >
          {{ $t('form.cancel') }}
        </v-btn>
        <v-btn
          v-if="isCreate"
          color="primary"
          :disabled="!valid || submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.create') }}
        </v-btn>
        <v-btn
          v-if="isUpdate"
          color="info"
          :disabled="!valid || submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.save') }}
        </v-btn>
        <v-btn
          v-if="isDelete"
          color="error"
          :disabled="submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from '@axios'
import toast from '@/store/toast'

export default {
  name: 'TimetableDialog',
  props: {
    data: {
      type: Object,
      default: () => ({
        dialog: false,
        id: null,
        type: 'create',
        subjects: [],
      }),
    },
    dialogModal: {
      type: Function,
      default: state => {
        this.data.dialog = state
      },
    },
    translationKey: {
      type: String,
      default: () => '',
    },
  },
  emits: ['update:table'],
  data: () => ({
    loading: true,
    submitLoading: false,
    valid: false,
    item: null,
    form: {
      day: null,
      study_type: null,
      subject_id: null,
      from: null,
      to: null,
      class_room: null,
    },
    study_types: [
      {
        title: 'الدراسة الصباحية',
        value: 'morning',
      },
      {
        title: 'الدراسة المسائية',
        value: 'evening',
      },
    ],
  }),
  computed: {
    isCreate() {
      return this.data.type === 'create'
    },
    isUpdate() {
      return this.data.type === 'update'
    },
    isDelete() {
      return this.data.type === 'delete'
    },
    days() {
      return Array.from({ length: 7 }, (a, i) => ({
        title: this.$(`days.${i}`),
        value: i,
      }));
    },
    timeArray() {
      const minutes = ['00', '15', '30', '45']
      const am = this.$('timePicker.am')
      const pm = this.$('timePicker.pm')

      return Array.from({ length: (21 - 8) * 4 + 1 }, (a, i) => {
        const hour = Math.floor((8 + i * (15 / 60)) % 24)
        const minute = minutes[i % 4]

        return {
          value: `${String(hour)
            .padStart(2, '0')}:${String(minute)
            .padStart(2, '0')}`,
          title: hour <= 12 ? `${String(hour)
            .padStart(2, '0')}:${minute} ${hour < 12 ? am : pm}` : `${String(hour - 12)
            .padStart(2, '0')}:${minute} ${pm}`,
        }
      })
    },

    endTimeArray() {
      const startIndex = this.timeArray.findIndex(time => time.value === this.form.from)

      return this.timeArray.slice(startIndex + 1)
    },
    rules() {
      const rules = {}
      rules.day = [
        v => v !== undefined || this.$t('form.required', [this.$t(`${this.translationKey}.modal.day`)]),
      ]

      rules.class_room = [
        v => v !== undefined || this.$t('form.required', [this.$t(`${this.translationKey}.modal.class_room`)]),
      ]

      rules.study_type = [
        v => v !== undefined || this.$t('form.required', [this.$t(`${this.translationKey}.modal.study_type`)]),
      ]

      rules.subject_id = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.subject_id`)]),
      ]

      rules.from = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.from`)]),
      ]

      rules.to = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.to`)]),
      ]

      return rules
    },
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(val) {
        try {
          this.loading = true
          if (val.id) {
            const stage = await axios.get(`/timetables/${val.id}`)
            this.item = stage.data.data
            this.form.from = this.item.from.slice(0, -3)
            this.form.to = this.item.to.slice(0, -3)
            this.form.day = this.item.day
            this.form.class_room = this.item.class_room
            this.form.subject_id = this.item.subject_id
          }
          if (!val.dialog) {
            this.resetForm()
          }
        } catch (error) {
          //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
        } finally {
          this.loading = false
        }
      },
    },
  },
  methods: {
    async submit() {
      try {
        this.submitLoading = true
        if (this.isDelete) {
          await axios.delete(`/timetables/delete/${this.data.id}`)
          await toast.dispatch('success', this.$t('form.success.delete'))
          this.$emit('update:table')
          this.dialogModal(false)

          return
        }
        if (this.$refs.form.validate() === false) {
          return
        }
        if (this.isCreate) {
          await axios.post('/timetables/create', this.form)
          await toast.dispatch('success', this.$t('form.success.create'))
        } else if (this.isUpdate) {
          await axios.post(`/timetables/edit/${this.data.id}`, this.form)
          await toast.dispatch('success', this.$t('form.success.update'))
        }
        this.$emit('update:table')
        this.dialogModal(false)
      } catch (error) {
        //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
      } finally {
        this.submitLoading = false
      }
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      this.form = {
        day: null,
        subject_id: null,
        from: null,
        to: null,
      }
      this.item = null
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.modal.${key}`, parameters)
    },
  },
}
</script>

<style scoped>

</style>
